<template>
  <div class="box">
    <homes ref="productImage"></homes>
    <div class="content">
      <div class="nav">
        <div class="creditrating">
          <div class="creditrating-box">
            <div class="info-title">班组评价信用报告</div>
            <div class="credit-evi">
              <div class="evi-box" @click="toWinding()">
                <i class="el-icon-document" style="font-size: 20px"></i>
                <span class="solid">|</span>
                信用存证
              </div>
            </div>
            <div class="creditrating-min">
              <!-- 左边 -->
              <div class="left">
                <div class="left-head">
                  <img
                    class="img"
                    :src="item.avatar ? $imgUrl(item.avatar) : srcImgUrl"
                    :onerror="$global.srcImgUrl"
                    alt=""
                  />
                  <div class="head-info">
                    <div class="name">{{ $processName(item.name) }}</div>
                    <div class="job">{{ showTeamType(item.teamType) }}</div>
                    <div class="grade">
                      <span> 信用分: </span>
                      <!-- <span style="color: red; font-size: 28px">{{
                        Number(obj.grade).toFixed(1)
                      }}</span> -->
                      <span
                        style="font-size: 28px"
                        class="score_text"
                        v-if="item.grade >= 80"
                        :style="{
                          color: gradeColor[0],
                          borderColor: gradeColor[0],
                        }"
                      >
                        {{ Number(item.grade).toFixed(1) }}
                      </span>
                      <span
                        style="font-size: 28px"
                        class="score_text"
                        v-if="item.grade < 80 && item.grade >= 60"
                        :style="{
                          color: gradeColor[1],
                          borderColor: gradeColor[1],
                        }"
                      >
                        {{ Number(item.grade).toFixed(1) }}
                      </span>
                      <span
                        style="font-size: 28px"
                        class="score_text"
                        v-if="item.grade < 60 && item.grade >= 40"
                        :style="{
                          color: gradeColor[2],
                          borderColor: gradeColor[2],
                        }"
                      >
                        {{ Number(item.grade).toFixed(1) }}
                      </span>
                      <span
                        style="font-size: 28px"
                        class="score_text"
                        v-if="item.grade < 40"
                        :style="{
                          color: gradeColor[3],
                          borderColor: gradeColor[3],
                        }"
                      >
                        {{ Number(item.grade).toFixed(1) }}
                      </span>
                      <span>分</span>
                    </div>
                  </div>
                </div>
                <div class="work-info">
                  {{ item.sex == "2" ? "女" : "男" }}
                  <span>|</span>
                  {{ item.age ? item.age : "" }}岁
                  <span v-if="item.age">|</span>
                  {{ item.nation ? item.nation : "" }}
                  <span v-if="item.nation">|</span>
                  {{ item.edu ? getPostEdu(item.edu) : "" }}
                </div>
                <div class="work">
                  职&nbsp;位 <span>:</span
                  ><span
                    class="spanInfoSize"
                    v-for="(data, ind) in item.postType"
                    :key="ind"
                  >
                    {{
                      ind == item.postType.length - 1
                        ? showPostType(postTypeList, data)
                        : showPostType(postTypeList, data) + ","
                    }}</span
                  >
                </div>

                <div>
                  <p class="person-work">核心成员</p>

                  <div class="star-info">
                    <div class="person">
                      <span class="person-name">{{
                        $processName(item.name)
                      }}</span>
                      <div class="post-type">
                        <span
                          class=""
                          v-for="(data, ind) in item.postType"
                          :key="ind"
                        >
                          {{
                            ind == item.postType.length - 1
                              ? showPostType(postTypeList, data)
                              : showPostType(postTypeList, data) + ","
                          }}</span
                        >
                      </div>
                    </div>

                    <div class="person">
                      <div class="assent-name">{{
                        showTeamType(item.teamType)
                      }}</div>
                      <span class="manager">{{
                        $global.geTel(item.phone)
                      }}</span>
                    </div>
                  </div>

                  <div
                    class="star-info"
                    v-for="(info, idx) in item.users"
                    :key="idx"
                  >
                    <div class="person">
                      <span class="person-name">{{
                        $processName(info.name)
                      }}</span>
                      <div class="post-type">
                        <span
                          class=""
                          v-for="(data, ind) in info.postType"
                          :key="ind"
                        >
                          {{
                            ind == info.postType.length - 1
                              ? showPostType(postTypeList, data)
                              : showPostType(postTypeList, data) + ","
                          }}
                        </span>
                      </div>
                    </div>

                    <div class="person">
                      <div class="assent-name">{{
                        showTeamType(item.teamType)
                      }}</div>
                      <span class="manager">{{
                        $global.geTel(info.phone)
                      }}</span>
                    </div>
                  </div>
                </div>

                <div>
                  <p class="project">项目经历</p>
                  <div
                    class="star-info"
                    v-for="(data, idx) in item.projects"
                    :key="idx"
                  >
                    <div class="box-assent">{{ data.project ? data.project : ''}}</div>
                    <span class="box" v-if="data.teamType"
                      >工种：{{
                        data.teamType ? showTeamType(data.teamType) : ""
                      }}</span
                    >
                    <div class="assent">
                      <div class="assent-name" v-if="data.company">{{ data.company }}</div>
                      <span class="assent-manager" v-if="data.startTime || data.endTime"
                        >{{ moment(data.startTime).format("YYYY-MM") }}-
                        {{ moment(data.endTime).format("YYYY-MM") }}</span
                      >
                    </div>
                  </div>
                </div>
              </div>

              <!-- 右边 -->
              <div class="right">
                <div class="mask-box" v-if="isSee">
                  <div class="pop-up">
                    <div class="pop-info">
                      <div class="title-info">
                        使用个人权益<br />即可查看用户信用评价
                      </div>
                      <!-- <el-button type="primary" @click="toSee" -->
                      <el-button type="primary" @click="clickEvaluate"
                        >查看评价</el-button
                      >
                    </div>
                  </div>
                </div>
                <!-- <h2 style="text-align: center">基本评价</h2>
                <biantu ref="biantu"></biantu> -->

                <h2 style="text-align: center; margin-top: 45px">信用评价</h2>
                <bian ref="bian"></bian>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <el-dialog
      class="dialog1"
      width="900px"
      :lock-scroll="false"
      :visible.sync="outerVisible"
      custom-class="previewDialog"
    >
      <div class="create-info">
        <div class="user-info">
          <div class="logo-title">
            <img class="logo-img" src="@/assets/home/logo.png" alt="" />
            <div class="logo-text">甄工匠</div>
          </div>
          <div class="title-name">信用评价存证信息</div>
          <div class="left">
            <p class="user-name">{{ $processName(item.name) }}</p>
            <div class="info-data">
              {{ item.sex == "2" ? "女" : "男" }}
              <span>|</span>
              {{ item.age }}岁
              <span>|</span>
              {{ $occlusionCard(item.idCard) }}
            </div>
            <p>
              职位: <span>{{ item.postName }}</span>
            </p>
          </div>
          <div class="right">
            <div class="grade-title">信用评价总得分</div>
            <p
              class="grade"
              v-if="item.grade >= 80"
              :style="{
                color: gradeColor[0],
                borderColor: gradeColor[0],
              }"
            >
              {{ Number(item.grade).toFixed(1) }}
            </p>
            <p
              class="grade"
              v-if="item.grade < 80 && item.grade >= 60"
              :style="{
                color: gradeColor[1],
                borderColor: gradeColor[1],
              }"
            >
              {{ Number(item.grade).toFixed(1) }}
            </p>
            <p
              class="grade"
              v-if="item.grade < 60 && item.grade >= 40"
              :style="{
                color: gradeColor[2],
                borderColor: gradeColor[2],
              }"
            >
              {{ Number(item.grade).toFixed(1) }}
            </p>
            <p
              class="grade"
              v-if="item.grade < 40"
              :style="{
                color: gradeColor[3],
                borderColor: gradeColor[3],
              }"
            >
              {{ Number(item.grade).toFixed(1) }}
            </p>
          </div>
        </div>
        <div class="detail-info">
          <div class="title-name">上链详情</div>
          <div v-for="(item, index) in data" :key="index" class="up-info">
            <div class="left">{{ item.name }}</div>
            <div class="right">{{ item.info }}</div>
          </div>
        </div>
      </div>
    </el-dialog>
    <!-- 底部 -->
    <footerss></footerss>

    <el-dialog title="提示" :visible.sync="dialogVisible" width="30%">
      <span>{{ tips }}</span>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogCancel">取 消</el-button>
        <el-button type="primary" @click="confirm(confirmType)"
          >确 定</el-button
        >
      </span>
    </el-dialog>
  </div>
</template>

<script>
import homes from "../../components/hometitle/hometitle.vue";
import footerss from "../../components/footer/footers.vue";
import biantu from "../personalCenter/creditrating/biantu/biantu.vue"; // 雷达图
import bian from "../personalCenter/creditrating/biantu/bian.vue";
import { jobPostType } from "@/api/position/position";
import {
  jobEvaluationDetail,
  getBCSGrad,
  getLoginUser,
  getLoginUserInfo,
} from "@/api/evaluation/evaluation";
import {
  subtractEquityNum,
  oneEquityNum,
  oneEquityLogsNum,
} from "@/api/equity/equity.js";
import moment from "moment";
import { detailTeam } from "@/api/team/team";
export default {
  components: {
    footerss,
    homes,
    biantu,
    bian,
  },
  data() {
    return {
      userId: "",
      orgId: "",
      userTeam: {},
      gradeColor: ["#67CF61", "#3B94F7", "#e6a23c", "#ff0000"],
      srcImgUrl: require("../../assets/home/people.png"),
      data: [
        {
          name: "区块高度",
          info: "",
        },
        {
          name: "上链时间",
          info: "",
        },
        // {
        //   name: "大小(KB)",
        //   info: "3.05",
        // },
        {
          name: "上链ID",
          info: "",
        },
        // {
        //   name: "确认数",
        //   info: "1971",
        // },
        {
          name: "上链状态",
          info: "",
        },
      ],
      outerVisible: false,
      item: {},
      item2: {
        mapType: {},
        mapTypeBase: {},
      },
      dict: [],
      orgType: [],
      jobEdu: [],
      cityList: [],
      jobExp: [],
      team_type: [],
      postTypeList: [], //职位类型列表
      isSee: true,
      option: {
        color: ["#fc9a40"],
        radar: {
          indicator: [],
        },
        series: [
          {
            name: "往年评分 vs 当前评分",
            type: "radar",
            data: [
              {
                value: [],
                name: "往年评分",
              }
            ],
          },
        ],
      },
      dataList: {
        color: ["#fc9a40"],
        radar: {
          indicator: [],
        },
        series: [
          {
            name: "往年评分 vs 当前评分",
            type: "radar",
            data: [
              {
                value: [],
                name: "往年评分",
              }
            ],
          },
        ],
      },
      dialogVisible: false,
      tips: "",
      confirmType: "",
      // jobVipEquity: [],
      // jobVipEquityId: '',
    };
  },
  computed: {},
  mounted() {
    this.loadData(this.$route.query.id);
  },
  methods: {
    moment,
    async loadData(idCard) {
      let res = await jobEvaluationDetail({ idCard, type:'3' });
      if (res.data.rows && res.data.rows.length > 0) {
        this.operationId = res.data.rows[0].id;
        this.item = res.data.rows[0];
        this.item2 = res.data.rows[1] ? res.data.rows[1] : this.item2;
        this.item.postType = this.item.postType.split(",");
        this.item.users.map((item) => {
          item.postType = item.postType.split(",");
        });
        // if (res.data.rows[1]) {
        //   this.item2 = res.data.rows[1];
        //   this.item2.mapType = res.data.rows[1].mapType == null ? (res.data.rows[1].mapType = {}) : res.data.rows[1].mapType;
        //   this.item2.mapTypeBase = res.data.rows[1].mapTypeBase == null ? (res.data.rows[1].mapTypeBase = {}) : res.data.rows[1].mapTypeBase;
        // }
        this.infoData();
      }
    },
    toWinding() {
      this.outerVisible = !this.outerVisible;
      getBCSGrad({ idCard: this.$route.query.id, userType: '3' }).then((res) => {
          if (res.data) {
            let info = res.data;
            info.Value = JSON.parse(info.Value);
            info.Timestamp = this.timestampToTime(info.Timestamp.slice(0, 10));
            info.BlockNum = info.BlockNum + 1;
            this.data[0].info = info.BlockNum;
            this.data[1].info = info.Timestamp;
            this.data[2].info = info.TxHash;
            this.data[3].info = info.IsDeleted ? "error" : "Success";
          } else {
            this.$message("用户暂无存证信息");
          }
        })
        // .catch((error) => {
        //   this.$message.error("用户暂无存证信息");
        // });
    },
    timestampToTime(timestamp) {
      var date = new Date(timestamp * 1000) //时间戳为10位需*1000，时间戳为13位的话不需乘1000
      var Y = date.getFullYear() + '-'
      var M =
        (date.getMonth() + 1 < 10
          ? '0' + (date.getMonth() + 1)
          : date.getMonth() + 1) + '-'
      var D = date.getDate() + ' '
      var h = date.getHours() + ':'
      var m = date.getMinutes() + ':'
      var s = date.getSeconds()
      return Y + M + D
    },
    infoData() {
      this.userId = localStorage.getItem("userId");
      this.orgId = localStorage.getItem("orgId");
      this.dict = JSON.parse(localStorage.getItem("dict"));
      this.orgType = this.dict.filter(
        (i) => i.code == "job_org_type"
      )[0].children;
      this.jobEdu = this.dict.filter((i) => i.code == "job_edu")[0].children;
      this.jobExp = this.dict.filter((i) => i.code == "job_exp")[0].children;
      this.team_type = this.dict.find((e) => e.code === "team_type").children;
      detailTeam({ jobUserId: this.userId }).then((res) => {
        if (res.data) {
          if (res.data.idCard == this.$route.query.id) {
            this.toSee();
          } else {
            this._oneEquityLogsNum();
          }
        } else {
          this._oneEquityLogsNum();
        }
      });
      this.getJobPostType();
    },

    // 显示班组类型
    showTeamType(val) {
      if (val) {
        let data = [];
        let orgType = val.split("");
        orgType.forEach((ti) => {
          this.team_type.forEach((item) => {
            if (ti == item.code) {
              data.push(item.name);
            }
          });
        });
        let title = data.join("/");
        return title;
      } else {
        return "";
      }
    },
    // 职位类型
    getJobPostType() {
      jobPostType({ code: "post_type" }).then((res) => {
        this.postTypeList = this.hasChildren(res.data[0].children);
      });
    },
    hasChildren(list) {
      list.forEach((item) => {
        if (item.children.length > 0) {
          this.hasChildren(item.children);
        } else {
          item.children = null;
        }
      });
      return list;
    },
    // 显示职位
    showPostType(list, id) {
      var title = "";
      for (let i = 0; i < list.length; i++) {
        if (list[i].id == id) {
          title = list[i].title;
          break;
        } else if (list[i].children && list[i].children.length > 0) {
          title = this.showPostType(list[i].children, id);
          if (title) return title;
        }
      }
      return title;
    },
    // 查询是否扣除过权益
    _oneEquityLogsNum() {
      let obj = {
        operationId: this.operationId,
      };
      if (this.orgId) {
        obj.jobOrgId = this.orgId;
        (obj.index = 3), (obj.userType = 2);
      } else if (this.userId) {
        obj.jobUserId = this.userId;
        (obj.index = 2), (obj.userType = 1);
      }
      oneEquityLogsNum(obj).then((res) => {
        console.log(res, "是否扣除过权益");
        if (res.success && res.data.status) {
          console.log("扣除过权益");
          this.toSee();
        }
      });
    },

    // 点击信用评价
    clickEvaluate() {
      if (this.userId || this.orgId) {
        this.dialogVisible = true;
        // this.jobVipEquityId = this.jobVipEquity.filter(i => i.code == 20)[0].name
        this.getEquityNum();
      } else {
        this.dialogVisible = true;
        this.tips = "请登录后查看";
        this.confirmType = 3;
      }
    },
    getEquityNum() {
      let obj = {};
      if (this.orgId) {
        obj.jobOrgId = this.orgId;
        (obj.index = 3), (obj.userType = 2);
      } else if (this.userId) {
        obj.jobUserId = this.userId;
        (obj.index = 2), (obj.userType = 1);
      }
      oneEquityNum(obj).then((res) => {
        console.log(res, "权益次数");
        if (res.success) {
          this.dialogVisible = true;
          if (res.data == 0) {
            this.tips = "当前权益次数不足，是否前往充值？";
            this.confirmType = 1;
          } else {
            this.tips = "查看信用评价将扣除您的相关权益，是否查看？";
            this.confirmType = 2;
          }
        }
      });
    },
    // 确定对话框
    confirm(confirmType) {
      // 1:前往充值 2:花权益购买
      if (confirmType == 1) {
        this.$router.push("/personalCenter/memberCenter");
      } else if (confirmType == 2) {
        this._subtractEquityNum();
      } else if (confirmType == "") {
        this.dialogVisible = false;
      } else if (confirmType == 3) {
        this.$router.push("/login");
      }
    },
    // 取消对话框
    dialogCancel() {
      this.dialogVisible = false;
      this.confirmType = "";
    },
    // 扣除权益次数
    _subtractEquityNum() {
      let obj = {
        // jobUserId: this.jobUserId,
        // jobVipEquityId: this.jobVipEquityId,
        operationId: this.operationId,
        subtractNum: 1,
      };
      if (this.orgId) {
        obj.jobOrgId = this.orgId;
        obj.index = 3;
        obj.userType = 2;
      } else if (this.userId) {
        obj.jobUserId = this.userId;
        obj.index = 2;
        obj.userType = 1;
      }
      subtractEquityNum(obj).then((res) => {
        if (res.success) {
          this.dialogVisible = false;
          this.toSee();
          this.$message.success("购买成功");
        } else {
          this.dialogVisible = false;
          this.$message.error("购买失败，请重试");
        }
      });
    },
    toSee() {
      this.isSee = false;
      this.mapTypeInfo();
      // this.mapTypeBaseInfo();
      var that = this;
      setTimeout(() => {
        // that.$refs.biantu.budil(this.dataList);
        that.$refs.bian.budil(this.option);
      }, 500);
    },
    // 信用评价
    // mapTypeInfo() {
    //   let keys = Object.keys(this.item.mapType);
    //   let values = Object.values(this.item.mapType);
    //   let values2 = Object.values(this.item2.mapType);
    //   this.option.radar.indicator.map((item, index) => {
    //     this.option.radar.indicator[index].name = keys[index]
    //       ? keys[index]
    //       : this.option.radar.indicator[index].name;
    //     this.option.radar.indicator[index].max = values[index]
    //       ? values[index][0] > 0
    //         ? values[index][0]
    //         : 0
    //       : 0;
    //     this.option.series[0].data[1].value[index] = values[index]
    //       ? values[index][1] >= 0
    //         ? values[index][1]
    //         : 0
    //       : 0;
    //     this.option.series[0].data[0].value[index] = values2[index]
    //       ? values2[index][1] >= 0
    //         ? values2[index][1]
    //         : 0
    //       : 0;
    //   });
    //   console.log(this.option.series[0].data, "信用评价");
    // },
    mapTypeInfo() {
      this.option.radar.indicator = []
      this.option.series[0].data[0].value = []
      let keys1 = Object.keys(this.item.mapType);
      let values1 = Object.values(this.item.mapType);
      values1.map((item,index)=>{
        let obj = {
          name: keys1[index],
          max:item[0]
        }
         this.option.radar.indicator.push(obj)
         this.option.series[0].data[0].value[index] = item[1]
      })
      console.log(this.option, "信用评价");
    },
    // 基本评价
    // mapTypeBaseInfo() {
    //   let keys = Object.keys(this.item.mapTypeBase);
    //   let values = Object.values(this.item.mapTypeBase);
    //   let values2 = Object.values(this.item2.mapTypeBase);
    //   this.dataList.radar.indicator.map((item, index) => {
    //     this.dataList.radar.indicator[index].name = keys[index]
    //       ? keys[index]
    //       : this.dataList.radar.indicator[index].name;
    //     this.dataList.radar.indicator[index].max = values[index]
    //       ? values[index][0] > 0
    //         ? values[index][0]
    //         : 0
    //       : 0;
    //     this.dataList.series[0].data[1].value[index] = values[index]
    //       ? values[index][1] >= 0
    //         ? values[index][1]
    //         : 0
    //       : 0;
    //     this.dataList.series[0].data[0].value[index] = values2[index]
    //       ? values2[index][1] >= 0
    //         ? values2[index][1]
    //         : 0
    //       : 0;
    //   });
    //   console.log(this.dataList.series[0].data, "基本评价");
    // },
    mapTypeBaseInfo() {
      this.dataList.radar.indicator = []
      this.dataList.series[0].data[0].value = []
      let keys1 = Object.keys(this.item.mapTypeBase);
      let values1 = Object.values(this.item.mapTypeBase);
      values1.map((item,index)=>{
        let obj = {
          name: keys1[index],
          max:item[0]
        }
         this.dataList.radar.indicator.push(obj)
         this.dataList.series[0].data[0].value[index] = item[1]
      })
      console.log(this.dataList, "基本评价");
    },

    getOrgType(id) {
      return this.orgTyp.length > 0
        ? this.orgType.filter((i) => i.code == id)[0].name
        : "";
    },
    getPostEdu(id) {
      return this.jobEdu.length > 0
        ? this.jobEdu.filter((i) => i.code == id)[0].name
        : "";
    },
    getPostExp(id) {
      return this.jobExp.length > 0
        ? this.jobExp.filter((i) => i.code == id)[0].name
        : "";
    },
    geTel(tel) {
      var reg = /^(\d{3})\d{4}(\d{4})$/;
      return tel.replace(reg, "$1****$2");
    },
    handleResize() {
      this.fullWidth = document.documentElement.clientWidth;
      this.fullHeight = document.documentElement.clientHeight;
    },
  },
};
</script>

<style scoped lang="scss">
::v-deep .dialog1 .el-dialog__header {
  display: none;
  padding: 0 !important;
}
::v-deep .dialog1 .el-dialog {
  background-color: rgba(254, 254, 254, 0);
  box-shadow: none;
}
::v-deep .dialog1 .el-dialog__body {
  padding: 0 !important;
  background-image: url("../../assets/course/up-img.png");
  background-repeat: no-repeat;
  background-size: 100% 100%;
}

.previewDialog {
  .create-info {
    display: flex;
  }

  .user-info {
    width: 28%;
    align-items: center;
    padding: 30px 30px;

    .logo-title {
      text-align: center;
      .logo-img {
        width: 47px;
        height: 53px;
      }
      .logo-text {
        color: #0d9ff9;
        font-size: 20px;
        font-weight: bold;
      }
    }
    .title-name {
      padding: 10px 20px;
      margin-top: 20px;
      font-size: 18px;
      text-align: center;
      font-weight: bold;
    }
    .user-name {
      font-size: 18px;
      font-weight: bold;
    }
    .left {
      .info-data {
        margin: 20px 0;
      }
    }
    .right {
      overflow: hidden;
      align-items: center;
      display: flex;
      .grade-title {
        font-size: 18px;
        margin-right: 20px;
      }
      .grade {
        color: #ff5a47;
        font-size: 28px;
        font-family: buer;
      }
    }
  }
  .detail-info {
    width: 55%;
    padding: 15px 0;
    margin-left: 40px;
    .title-name {
      padding: 10px 0;
      font-size: 18px;
      font-weight: bold;
    }
    .up-info {
      display: flex;
      justify-content: space-around;
      box-sizing: border-box;
      padding: 10px;
      margin: 7px 0;

      .left {
        width: 20%;
        font-size: 16px;
      }
      .right {
        flex: 1;
        width: 50%;
        font-size: 16px;
      }
    }
  }
}
.credit-evi {
  width: 986px;
  margin: 0 auto;
  margin-bottom: 20px;

  .evi-box {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 145px;
    height: 40px;
    line-height: 40px;
    background-color: #029aff;
    color: #fff;
    font-size: 14px;
    border-radius: 7px;
    text-align: center;
    margin: auto 80px auto auto;
    .solid {
      margin: 0 18px;
      color: #fff;
    }
  }
}
.content {
  min-height: 650px;
  background: url("../../assets/course/result_bg.png");
  padding-top: 100px;
  padding-bottom: 100px;
}
.info-title {
  text-align: center;
  font-size: 36px;
  font-weight: 500;
  margin-bottom: 30px;
}
.itemName {
  padding: 0 86px;
  padding-bottom: 22px;
  .dividerFont {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 0 50px;
    span {
      font-size: 24px;
      margin-bottom: 6px;
    }
  }
}

.creditrating {
  background-image: url("~@/assets/course/border-img.png");
  background-repeat: no-repeat;
  background-size: 100% 100%;
  padding: 100px;

  width: 986px;
  margin: 0 auto;
  // background: rgba(255, 255, 255, 1);
  border-radius: 3px;

  .creditrating-box {
    span {
      color: rgba(51, 51, 51, 1);
      font-size: 22px;
    }
    .title {
      position: relative;
      .mes {
        font-size: 25px;
        position: absolute;
        margin-left: 20px;
      }
    }
  }

  .creditrating-min {
    display: flex;
    justify-content: space-around;
    .left {
      width: 500px;
      color: rgba(51, 51, 51, 1);
      font-size: 18px;
      line-height: 36px;
      margin-top: 50px;
      .left-head {
        display: flex;
        justify-content: center;
        // align-items:center;
        .img {
          width: 120px;
          height: 120px;
          object-fit: cover;
        }
        .head-info {
          margin-left: 42px;
          .name {
            font-size: 28px;
            font-weight: bold;
          }
          .job {
            opacity: 1;
            height: 28px;
            line-height: 28px;
            border-radius: 3px;
            background: #ebf7ff;
            margin-bottom: 20px;
            margin-top: 10px;
            font-size: 12px;
            color: #3c87f9;
            padding-left: 10px;
          }
          .grade {
            font-size: 20px;
          }
        }
      }
      .work-info,
      .work {
        margin-top: 10px;
        margin-left: 75px;
      }
    }

    .person-work {
      margin-top: 50px;
      margin-left: 85px;
      color: #000;
      font-size: 18px;
      font-weight: 400;
    }

    .star-info {
      width: 345px;
      background: #fafafa;
      // background-color: red;
      box-sizing: border-box;
      border-radius: 3px;
      opacity: 1;
      margin-left: 85px;
      margin-bottom: 20px;
      .person {
        display: flex;
        justify-content: space-between;
        padding-top: 5px;

        .person-name {
          font-size: 16px;
          font-weight: 400;
          color: #000;
          padding-left: 10px;
        }
        .post-type {
          width: 150px;
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
          text-align: right;
          margin-right: 8px;
          span {
            font-size: 14px;
          }
        }
        .assent-name {
          font-size: 16px;
          font-weight: 400;
          color: #666666;
          padding-left: 10px;
          width: 150px;
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
          text-align: left;
        }
        .manager {
          font-size: 14px;
          // font-weight: 400;
          color: #666;
          padding-right: 10px;
        }
      }
    }

    .project {
      margin-top: 50px;
      margin-left: 85px;
      color: #000;
      font-size: 18px;
      font-weight: 400;
    }
    .box-assent {
      font-size: 16px;
      font-weight: 400;
      color: #333333;
      padding-left: 10px;
    }
    .box {
      font-size: 14px;
      font-weight: 400;
      color: #666666;
      padding-left: 10px;
    }
    .assent {
      display: flex;
      justify-content: space-between;
      .assent-name {
        font-size: 16px;
        font-weight: 400;
        color: #666666;
        padding-left: 10px;
        width: 150px;
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
          text-align: left;
      }
      .assent-manager {
        font-size: 14px;
        // font-weight: 400;
        color: #666666;
        padding-right: 10px;
      }
    }

    .right {
      width: 500px;
      color: rgba(51, 51, 51, 1);
      font-size: 18px;
      line-height: 36px;
      position: relative;
      .mask-box {
        position: absolute;
        width: 500px;
        height: 100%;
        backdrop-filter: blur(8px);
        z-index: 3;
        .pop-up {
          width: 351px;
          height: 246px;
          background-image: url("~@/assets/course/pop-img.png");
          background-repeat: no-repeat;
          background-size: 100% 100%;
          margin: 50% auto;
          border-radius: 3px;
          overflow: hidden;
          .pop-info {
            width: 250px;
            height: 170px;
            background-color: #fff;
            margin: 38px auto;
            border-radius: 14px;
            text-align: center;
            font-size: 14px;
            overflow: hidden;
            .title-info {
              margin: 20px;
            }
          }
        }
      }
    }
  }
}

::v-deep {
  .el-pagination.is-background .btn-prev {
    /*对下一页的按钮样式进行修改*/
    background: #fff;
  }
  .el-pagination.is-background .btn-next {
    /*对上一页的按钮样式进行修改*/
    background: #fff;
  }
  .el-pagination.is-background .el-pager li:not(.disabled) {
    background-color: #fff; // 进行修改未选中背景和字体
    // color: #fff;
  }
  .el-pagination.is-background .el-pager li:not(.disabled).active {
    background-color: rgb(19, 138, 206); // 进行修改选中项背景和字体
    color: rgb(255, 255, 255);
  }
}
</style>
